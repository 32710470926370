import React, { useMemo } from 'react'
import { Particles } from './Particles'
import './Background.css'
import { Rain } from './Rain'

const imageSrcs = [
    '/clear/0-min.png',
    '/clear/3000-min.png',
    '/clear/12000-min.png',
    '/clear/15000-min.png',
    '/clear/23000-min.png',
    '/rain/0-min.png',
    '/rain/9000-min.png',
    '/rain/12000-min.png',
    '/rain/15000-min.png',
    '/rain/23000-min.png',
].map(src => process.env.PUBLIC_URL + '/backgrounds' + src)

export const Background = React.memo(() => {
    const imageSrc = useMemo(() => imageSrcs[Math.floor(imageSrcs.length * Math.random())], [])

    return <>
        {imageSrc.includes('rain') && <Rain />}
        {imageSrc.includes('clear') && <Particles />}
        <img className="background"
            src={imageSrc}
            alt="background"></img>
    </>
})

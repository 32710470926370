import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import axios from 'axios';

import { SERVER_ADDRESS_FULL, SERVER_ADDRESS_SHORT } from '../env'

import logoDimImage from './images/logo_dim.png';
import logoBrightImage from './images/logo_bright.png';

import mapIcon from '../../images/map.svg';
import discordIcon from '../../images/discord.svg';
import questionIcon from '../../images/question.svg';

import './LandingPage.css';
import { openMap } from '../utils/openMap'
import { openDiscordServer } from '../utils/openDiscordServer'

export const LandingPage = () => {
    const history = useHistory();

    const serverAddressFieldInputRef = useRef();

    const [canCopy, setCanCopy] = useState(true);
    const [isCopied, setIsCopied] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isServerOnline, setIsServerOnline] = useState(false);
    const [playersOnline, setPlayersOnline] = useState(0);
    const [serverVersion, setServerVersion] = useState(null);
    const [showFullAddress, setShowFullAddress] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        let isCancelled = false;
        (async () => {
            const result = await axios.get(`https://api.mcsrvstat.us/2/${SERVER_ADDRESS_FULL}`);

            if (isCancelled) return;

            if (result.data && result.data.online) {
                setIsServerOnline(result.data.online);
                setPlayersOnline(result.data.players.online);
                setServerVersion(result.data.version);
            }

            setIsLoading(false);
        })();

        return () => {
            isCancelled = true;
        };
    }, []);

    const selectAddress = useCallback(() => {
        setShowFullAddress(true);

        setTimeout(() => {
            serverAddressFieldInputRef.current.focus();
            serverAddressFieldInputRef.current.select();
        }, 50);
    }, [setShowFullAddress]);

    const handleAddressClick = useCallback(async () => {
        if (isCopied) return;

        try {
            await copyToClipboard(SERVER_ADDRESS_FULL);
        } catch (error) {
            console.warn('Could not copy server address:', error);
            setCanCopy(false);
            selectAddress();
        }

        setIsCopied(true);

        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    }, [isCopied, setIsCopied, selectAddress]);

    const handleAddressFieldClick = useCallback(() => {
        selectAddress();
    }, [selectAddress]);

    const handleDiscordClick = useCallback(() => {
        openDiscordServer();
    }, []);

    const handleMapClick = useCallback(() => {
        openMap();
    }, []);

    const handleFaqClick = useCallback(() => {
        history.push('/guide');
    }, [history]);

    return <div className="page landing-page">
        <div className="landing-page__spacer" />
        <div className="landing-page__logo">
            <img className="landing-page__logo-image landing-page__logo-image--bright" src={logoBrightImage} alt="logo" />
            <img className="landing-page__logo-image" src={logoDimImage} alt="logo" />
            {isServerOnline && <div className="landing-page__version">{serverVersion}</div>}
        </div>
        <div className="landing-page__address-block">
            <div className="landing-page__address" onClick={handleAddressClick}>
                <div className="landing-page__address-field" onClick={handleAddressFieldClick}>
                    <input ref={serverAddressFieldInputRef} value={
                        showFullAddress
                            ? SERVER_ADDRESS_FULL
                            : SERVER_ADDRESS_SHORT
                    } readOnly />
                </div>

                <button className={classnames(
                    'landing-page__address-copy-button',
                    isCopied && 'landing-page__address-copy-button--copied',
                )}>
                    {isCopied
                        ? canCopy
                            ? 'Copied'
                            : 'Press Ctrl+C'
                        : 'Copy IP'}
                </button>
            </div>
        </div>
        <div className="landing-page__actions">
            <div className="landing-page__actions-row">
                <button className="landing-page__action" disabled={!isServerOnline} onClick={handleMapClick}>
                    <img className="landing-page__action-icon" src={mapIcon} alt="Map" />
                    <span>Map</span>
                </button>
                <button className="landing-page__action" onClick={handleDiscordClick}>
                    <img className="landing-page__action-icon" src={discordIcon} alt="Discord" />
                    <span>Discord</span>
                </button>
                <button className="landing-page__action" onClick={handleFaqClick}>
                    <img className="landing-page__action-icon" src={questionIcon} alt="Guide" />
                    <span>Guide</span>
                </button>
            </div>
        </div>
        <div className="landing-page__spacer" />
        {isLoading && <div className="landing-page__loading"></div>}
        <div className="landing-page__server-status">
            <span className="landing-page__server-status-text">
                {isLoading
                    ? <>Connecting to the server...</>
                    : isServerOnline
                        ? <>
                            {playersOnline} {plurify(
                                playersOnline,
                                { single: 'player', few: 'players', many: 'players' }
                            )} online
                        </>
                        : <>Server is offline</>}
            </span>
        </div>
    </div>;
};

function plurify(amount, { single, few, many }) {
    if (amount === 0) {
        return many;
    }

    if (amount === 1) {
        return single;
    }

    if (amount <= 4) {
        return few;
    }

    if (amount <= 20) {
        return many;
    }

    const lastDigit = Number(String(amount).slice(-1));

    return plurify(lastDigit, { single, few, many });
}

async function copyToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyToClipboard(text);
        return;
    }

    await navigator.clipboard.writeText(text);
}

function fallbackCopyToClipboard(text) {
    const textArea = document.createElement('textarea');

    try {
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        const successful = document.execCommand('copy');
        if (!successful) {
            throw new Error(`Clipboard copy operation was unsuccessful: ${successful}`);
        }
    } finally {
        document.body.removeChild(textArea);
    }
}

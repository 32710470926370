import React, { useMemo } from 'react';
import TsParticles from "react-tsparticles";
import { loadFull } from "tsparticles";

export const Rain = React.memo(() => {
  const direction = useMemo(() => 80 + Math.random() * 20, [])

  return (
    <TsParticles
      id="tsparticles"
      init={(main) => loadFull(main)}
      options={{
        fullScreen: {
          zIndex: -1,
        },
        particles: {
          color: {
            value: "#ffffff"
          },
          lineLinked: {
            blink: false,
            color: "#000",
            consent: false,
            distance: 150,
            enable: false,
            opacity: 0,
            width: 0
          },
          rotate: {
            value: direction,
            random: false,
            direction: "clockwise",
            animation: {
              enable: false,
              speed: 5,
              sync: false
            }
          },
          move: {
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200
            },
            bounce: false,
            direction: direction,
            enable: true,
            outMode: "out",
            random: true,
            speed: {
              min: 25,
              max: 75,
            },
            straight: true
          },
          number: {
            density: {
              enable: true,
              area: 800
            },
            limit: 0,
            value: 200
          },
          opacity: {
            animation: {
              enable: false,
              minimumValue: 0.1,
              speed: 1,
              sync: false
            },
            random: false,
            value: 0.5
          },
          shape: {
            character: {
              fill: false,
              font: "Verdana",
              style: "",
              value: "*",
              weight: "400"
            },
            image: [],
            polygon: {
              nb_sides: 5
            },
            stroke: {
              color: "#bbbbbb",
              width: 3,
              opacity: 0.1
            },
            type: "line"
          },
          size: {
            animation: {
              enable: false,
              minimumValue: 0.1,
              speed: 40,
              sync: false
            },
            random: true,
            value: {
              min: 25,
              max: 75,
            }
          }
        },
      }}
    />
  );
});

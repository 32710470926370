import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import backIcon from '../../images/back.svg';
import './TextPage.css';

export const TextPage = ({ children, wrapperClass = '' }) => {
    const history = useHistory();

    const handleBackClick = useCallback(() => {
        history.push('/');
    }, [history]);

    return <div className='page text-page'>
        <div className={`text-page__wrapper ${wrapperClass}`}>
            <div className="text-page__back-button text-page__back-button--top" onClick={handleBackClick}>
                <img className="text-page__back-button-icon" src={backIcon} alt="Go back" />
                <div className="text-page__back-button-label">Go back</div>
            </div>
            <div className='text-page__content'>
                {children}
            </div>
            <div className="text-page__back-button text-page__back-button--bottom" onClick={handleBackClick}>
                <img className="text-page__back-button-icon" src={backIcon} alt="Go back" />
                <div className="text-page__back-button-label">Go back</div>
            </div>
        </div>
    </div>;
};

import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import { AppRouter } from './app/AppRouter';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppRouter />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// Resize body to screen height

let timeoutId;
function scheduleBodyHeightUpdate() {
    if (timeoutId) return;

    updateBodyHeight();
    timeoutId = setTimeout(() => {
        timeoutId = null;
        updateBodyHeight();
    }, 50);
}

function updateBodyHeight() {
    document.body.style.height = `${window.innerHeight}px`;
}

window.addEventListener('resize', scheduleBodyHeightUpdate);
window.addEventListener('orientationchange', scheduleBodyHeightUpdate);
updateBodyHeight();

import escapeMd from 'markdown-escape'
import { useEffect, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { TextPage } from '../shared/TextPage';

function selectElementContents(element) {
  const range = document.createRange();
  range.selectNodeContents(element);

  const sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
}

const MarkdownCode = ({ node, inline, children, ...props }) => {
  const ref = useRef(null);

  function handleClick() {
    if (ref?.current) {
      selectElementContents(ref?.current)
    }
  }

  return <code ref={ref} onClick={handleClick} {...props}>
    {children}
  </code>
}

const defaultVariables = {
  mapUrl: process.env.REACT_APP_MAP_URL,
  serverAddressFull: process.env.REACT_APP_SERVER_ADDRESS_FULL,
  serverAddressIp: process.env.REACT_APP_SERVER_ADDRESS_IP,
  discordServerUrl: process.env.REACT_APP_DISCORD_SERVER_URL,
}

export const MarkdownPage = ({ markdownUrl, variables = {} }) => {
  const [markdown, setMarkdown] = useState(null);
  const hasLoaded = Boolean(markdown);

  useEffect(() => {
    (async () => {
      const response = await fetch(markdownUrl);
      let markdown = await response.text();

      for (const [key, value] of Object.entries({ ...defaultVariables, ...variables })) {
        markdown = markdown.replaceAll(`{{${key}}}`, escapeMd(value));
        markdown = markdown.replaceAll(`{{${key}:raw}}`, value);
      }

      setMarkdown(markdown);
    })();
  }, [markdownUrl, variables])

  if (!hasLoaded) return <></>

  return <TextPage>
    <ReactMarkdown children={markdown} components={{
      code: MarkdownCode,
    }} />
  </TextPage>;
};
